import React, { useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { Layout } from '@components/Layout';
import { SEO } from '@components/SEO';
import { PageBlock } from '@components/PageBlock';
import { GetStarted } from '@components/GetStarted';
import { Proposition } from '@components/Proposition';
import { PageContainer } from '@components/PageContainer';

const RD = () => {
  const [page, setPage] = useState(0);

  const propositions = [
    {
      title: 'Professional',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Advice',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Help',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Solution',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Expert',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Analysis',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    },
    {
      title: 'Alternatives',
      content:
        "Professional analysis and expert advice you can rely on. We'll find the best way to make your solution efficient and offer cost-effective alternatives."
    }
  ];

  const handlePageChange = i => {
    setPage(i);
  };

  return (
    <Layout>
      <SEO title="R&Ds for Partners" />
      <div className="hidden lg:block">
        <ReactPageScroller
          blockScrollDown={page >= 1}
          renderAllPagesOnFirstRender={false}
          animationTimer={0}
          pageOnChange={handlePageChange}
        >
          <PageBlock
            header={false}
            title="R&Ds for Partners"
            titleContent={
              <span>
                Professional analysis and expert advice you can rely on. We&apos;ll find the best way to make your
                solution efficient and offer cost-effective alternatives.
              </span>
            }
            image={require('@images/comp.png')}
            content={
              <div className="flex flex-col justify-between h-full">
                <div>
                  <h3 className="H3">What we do to help you with your R&D:</h3>
                </div>
                <div>
                  {propositions.slice(0, 2).map(item => (
                    <Proposition key={item.title} item={item} />
                  ))}
                </div>
                <div>
                  <span>Scroll down to see our propositions</span>
                </div>
              </div>
            }
          />
          <GetStarted />
        </ReactPageScroller>
      </div>
      <div className="lg:hidden">
        <PageBlock
          title="R&Ds for Partners"
          titleContent={
            <span>
              Professional analysis and expert advice you can rely on. We&apos;ll find the best way to make your
              solution efficient and offer cost-effective alternatives.
            </span>
          }
          image={require('@images/comp.png')}
          content={
            <div>
              <div>
                <h3 className="H3 mb-2">What we do to help you with your R&D:</h3>
              </div>
              <div>
                {propositions.map(item => (
                  <Proposition key={item.title} item={item} />
                ))}
              </div>
            </div>
          }
        />
        <GetStarted />
      </div>
      <PageContainer />
    </Layout>
  );
};

export default RD;
